import React, { useState } from "react";

const Modpage = ({ socket }) => {
    const [selectedTeam, setSelectedTeam] = useState("red");

    const handleTeamChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    const handleEmit = (value, sound) => {
        // Emit the 'points' event with the selected team and the provided value
        socket.emit("points", { team: selectedTeam, value:value, sound:sound });
    };

    return (
        <div className="App" style={{padding: '20px', textAlign: 'center'}}>
            {/* Dropdown Menu for Selecting Team */}
            <label htmlFor="team-select">Choose a team: </label>
            <select
                id="team-select"
                value={selectedTeam}
                onChange={handleTeamChange}
                style={{marginBottom: '20px'}}
            >
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="orange">Orange</option>
                <option value="purple">Purple</option>
            </select>

            {/* Buttons for Emitting Events */}
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: '10px',
                    maxWidth: '300px',
                    margin: '0 auto',
                }}
            >
                <button onClick={() => handleEmit(3, 1)}>Bingo Add</button>
                <button onClick={() => handleEmit(-3, 0)}>Bingo Remove</button>
                <button onClick={() => handleEmit(3, 2)}>Challenge First Add</button>
                <button onClick={() => handleEmit(-3, 0)}>Challenge First Remove</button>
                <button onClick={() => handleEmit(1, 3)}>Challenge After Add</button>
                <button onClick={() => handleEmit(-1, 0)}>Challenge After Remove</button>
                <button onClick={() => handleEmit(3, 5)}>Stream First Add</button>
                <button onClick={() => handleEmit(-3, 0)}>Stream First Remove</button>
                <button onClick={() => handleEmit(2, 5)}>Stream Second Add</button>
                <button onClick={() => handleEmit(-2, 0)}>Stream Second Remove</button>
                <button onClick={() => handleEmit(1, 5)}>Stream Third Add</button>
                <button onClick={() => handleEmit(-1, 0)}>Stream Third Remove</button>
            </div>
            <button onClick={() => handleEmit(0, 0)}>Remove Task Text</button>
        </div>
    );
};

export default Modpage;
