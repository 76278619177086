import React, {useEffect, useState} from "react";
import backgroundImage from "../Layout-Background9.png";
import BingoTable from "../components/BingoTable";
import Viewer from "../components/Viewer";
import VDOPlayer from "../components/VDOPlayer";
import PointsDisplay from "../components/PointsDisplay";
import SoundPlayer from "../components/SoundPlayer";
import Countdown from "../components/Countdown";
import MessageDisplay from "../components/MessageDisplay";

const Board = ({socket}) => {
    const [playerInfo] = useState({
        username: "None",
        team: "None",
    });

    const [activeTeams, setActiveTeams] = useState([]);

    useEffect(() => {
        socket.on("receive_field", (data) => {
            alert(data.payload);
        });

        // Listen for active teams and update state
        socket.on("activeTeams", (data) => {
            setActiveTeams(data.activeTeams);
        });

        return () => {
            socket.off("receive_field");
            socket.off("activeTeams");
        };
    }, [socket]);

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        height: "1080px",
        width: "1920px",
    };

    const wrapperStyle = {
        position: "relative",
        marginLeft: "-40px", // Move 30px to the right
        top: "305px",
        width: "638px",
        transform: "scale(0.8)", // Scale down to 60%
        //border: "5px solid white",
    };

    const points = {
        display: "absolute",
        justifyContent: "center",
        alignItems: "center",
        transform: "scale(1)",
        marginTop: "180px",
        marginRight: "900px",
        marginLeft: "515px"
    };

    const countdown = {
        display: "absolute",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "-545px",
        marginRight: "500px",
        marginLeft: "1000px",
        transform: "scale(2)",
    }

    const camera = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "scale(1)",
        width: "100%",
        marginTop: "750px",
    };

    const chat = {
        display: "flex",
        width: "50%",
        alignItems: "left",
        transform: "scale(1)",
        marginTop: "0px",
        marginLeft: "515px",
    }

    const streamer_task = {
        display: "flex",
        width: "50%",
        alignItems: "left",
        transform: "scale(1)",
        marginTop: "-375px",
        marginLeft: "515px",
    }

    return (
        <div>
            <div style={backgroundStyle}>
                <div style={wrapperStyle}>
                    <BingoTable socket={socket} playerInfo={playerInfo}/>
                </div>
                <div style={countdown}>
                    <Countdown socket={socket}/>
                </div>
                <div className="camera" style={camera}>
                    <VDOPlayer socket={socket}/>
                </div>
                <div style={streamer_task}>
                    <MessageDisplay socket={socket} type={"streamer_task"}/>
                </div>
                <div style={chat}>
                    <MessageDisplay socket={socket} type={"task"}/>
                </div>
            </div>
            <SoundPlayer socket={socket}/>
        </div>
    );
};

export default Board;
