import React, { useState, useEffect } from 'react';

const MessageDisplay = ({ socket, type }) => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Listen for 'update_team_points' event
        socket.on(`message_${type}`, (message) => {
            // Convert the message to a string if it's an object
            setMessage(typeof message === 'object' ? JSON.stringify(message) : message);
        });

        // Cleanup the listener when the component is unmounted
        return () => {
            socket.off(`message_${type}`);
        };
    }, [socket, type]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: 20,
            fontWeight: 'bolder',
            fontFamily: 'Roboto, sans-serif',
            gap: '1px',
            color: 'rgba(0, 0, 0, 1)',
            backgroundColor: 'rgba(255, 255, 255, 0.6)',  // Grey background with 30% transparency
            padding: '1px',
            borderRadius: '5px'  // Optional: add some rounded corners for better aesthetics
        }}>
            <p>{message}</p>
        </div>
    );
};

export default MessageDisplay;
