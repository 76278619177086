import React, { useEffect } from "react";

const SoundPlayer = ({ socket }) => {
    useEffect(() => {
        const playSound = (data) => {
            const shiny = new Audio("sounds/shiny.mp3");
            const bingo = new Audio("sounds/bingo.mp3");
            const points = new Audio("sounds/3points.mp3");
            const point = new Audio("sounds/1point.mp3");
            const aufgabe = new Audio("sounds/aufgabe.mp3");
            const aufgabe_points = new Audio("sounds/aufgabe_points.mp3");
            shiny.volume = 0.2;
            bingo.volume = 0.1;
            points.volume = 0.2;
            point.volume = 0.2;
            aufgabe_points.volume = 0.2;
            aufgabe.volume = 0.2;

            if (data==1) {
                bingo.play();
            }else if(data==2) {
                points.play();
            }else if(data==3) {
                point.play();
            }else if(data==4) {
                shiny.play();
            }else if(data==5) {
                aufgabe_points.play();
            }else if(data==6) {
                aufgabe.play();
            }
        };

        socket.on("play_sound", (data) => {
            playSound(data);
        });
    }, [socket]);

    return <div></div>;
};

export default SoundPlayer;
